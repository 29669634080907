import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index' 

// 审核（CCC）
export const reviewLeadFail = (params = {}) => request.post(`${SERVER.LEAD_SERVICE}/api/v1/leadFail/hatchReview`, params).then(thenResolve)

export const clueListPost = async (params)=>{
  return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/hatchList`, params).then(thenResolve) 
}

export const listCountPost = async (params)=>{
  return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/hatchListCount`, params).then(thenResolve) 
}

// 根据当前门店来获取配置
export const getHatchRuleDetail = (params = {}) => request.get(`${SERVER.LEAD_SERVICE}/api/v1/hatchLeadConfig/detail`, { params }).then(thenResolve)

// 分配
export const syncLeadAllocation = async (params)=>{
  return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/external/lead/syncLeadAllocation`, params).then(thenResolve) 
}
