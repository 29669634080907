<script>
import phoneImg from '@/images/phone.svg'
import { judgeMobile } from '@/utils/index'
import { copyPhone } from '@/utils'
import { mapGetters } from 'vuex'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
import { debounce } from 'lodash'
export default {
  props:{
    name:{
      type: String,
      default:''
    },
    phone: { // 客户手机号
      type: String,
      default:''
    },
    clueId:{
      type: [Number,String],
      default: ''
    },
    appointmentId:{
      type: [Number,String],
      default: ''
    },
    isFollow:{
      type: Boolean,
      default: false
    },
    // 是否是自己的线索
    oneself: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      phoneImg
    }
  },
  computed:{
    judgeMobile,
    ...mapGetters(['hasMapping', 'userInfo']),
  },
  methods:{
    onCallPhone: debounce(async function(){
      // https://jira.nx-code.com/browse/DSS-223
      // https://conf.inner.vwadsso.cc/pages/viewpage.action?pageId=35695670
      const { judgeMobile, phone, isFollow, clueId, appointmentId, hasMapping, oneself } = this
      // 移动端直接拨打
      if (judgeMobile){
        if (hasMapping && oneself) { // 是自己的线索 且开通了虚拟号的门店 查询虚拟号
          const params = {
            leadId: clueId,
            userId: this.userInfo.id
          }
          loading.showLoading()
          const result = await clueServices.generatePhonex(params)
          loading.hideLoading()
          const { code, data } = result
          if (code === 0 && data) {
            location.href = `tel:${data}`
          } else {
            location.href = `tel:${phone}`
          }
          return false
        } else {
          if (phone.indexOf('*') !== -1){
            location.href = 'tel:'
          } else {
            location.href = `tel:${phone}`
          }
        }
        if (isFollow){
          this.$router.push({
            path: '/clue-follow',
            query: {
              id: clueId,
              appointmentId,
            }
          })
        }
      } else { // 非移动端 复制
        this.$toast(this.$t('请使用手机外呼'))
      }
    }, 1000) ,
  }
}
</script>
<template>
<div class="img" @click.stop="onCallPhone">
  <img :src="phoneImg" />
  <span v-if="name" style="margin-left:8px;">{{ name }}</span>
</div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
</style>
