<script>
export default {
  props:{
    level:{
      type: String,
      default:''
    } 
  },
}
</script>
<template>
<span :class="[level]" class="box">{{ level }}</span>
</template>
<style lang="less" scoped>
.box{
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
}
.H{
  background-color: #E4002C;
}
.A{
  background-color: #FA5410;
}
.B{
  background-color: #FAAD14;
}
.C{
  background-color: #2F54ED;
}
.U{
  background-color: #B0B3B4;
}
</style>
