<template>
	<div class="single-chat-index-page safe-area-inset-bottom">
		<!-- <search-bar-new
			placeholder-text="请输入客户姓名/电话/客服"
			:showScreen="false"
			:showCategory="false"
			@input="
				(val) => {
					searchStr = val
				}
			"
			@search="onRefresh"
		>
		</search-bar-new>
		<p class="total">共{{ totalCount }}条数据</p>
		<List ref="list" :fetchApi="fetchApi">
			<template v-slot:default="{ item, index }">
				<div class="item-wrap">
					<clue-item :dataSource="item" @goDetail="goDetail(item)">
						<div slot="actions" class="actions" @click.stop="openChat(item)">
							<img
								v-if="item.userWeChat"
								width="32px"
								:src="require('@/images/qw.png')"
							/>
						</div>
					</clue-item>
					<div class="select-icon" @click.stop="onSelect(item)">
						<van-icon
							v-if="currentObj.id == item.id"
							name="checked"
							color="#EED484"
							size="16"
						/>
						<van-icon v-else size="16" name="circle" color="#0D171A" />
					</div>
				</div>
			</template>
		</List>
		<div class="footer safe-area-inset-bottom">
			<van-button
				type="primary"
				size="small"
				:disabled="!currentObj.id"
				@click="onBind"
			>
				绑定线索与当前客户微信
			</van-button>
		</div> -->
    <van-overlay :show="show" class-name="no-data-overlay">
      <Empty description="无客户线索信息" />
    </van-overlay>
	</div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import List from '@/components/baseList/list.vue'
import ClueItem from './components/clue-item'
import QwIcon from '@/components/card-view/qw-icon.vue'
import loading from '@/utils/loading'
import { clueListPost } from '@/services/clueIncubationServices.js'
import baseDataServices from '@/services/baseDataServices'
import clueServices from '@/services/clueServices'
import wxsdk from '@/scripts/wxsdk'

export default {
  components: {
    ClueItem,
    List,
    SearchBarNew,
    QwIcon,
  },
  props: ['contextEntry'],
  data() {
    return {
      show: false,
      currentObj: {},
      searchStr: '',
      totalCount: 0,
      userId: '',
      chatId: '',
      loading: false,
    }
  },
  async created() {
    
    const params = await this.getCurExternalId()
    
    const data = await clueServices.hatchDetailByWechat(params)
    if (data && data.code === 0 && data.data) this.goDetail({ id: data.data.id }, true)
    else { 
      this.show = true
      data.code !== 0 && this.$toast(data.msg)
    }
  },
  methods: {
    async getCurExternalId() {
      let params = {}
      if (this.contextEntry == 'single_chat_tools' && !this.userId) {
        this.userId = await wxsdk.getCurExternalContact()
        params = { userWeChat: this.userId, chatGroupId: null }
      } else if (this.contextEntry == 'group_chat_tools' && !this.chatId) {
        this.chatId = await wxsdk.getCurExternalChat()
        params = { chatGroupId: this.chatId, userWeChat: null }
      }
      return params
    },
    openChat({ userWeChat }) {
      wxsdk.openExternalUserChat(userWeChat).catch((error) => {
        this.$toast(error.message)
        console.error(error)
      })
    },
    async onBind() {
      const { userWeChat, userWeChatName, id } = this.currentObj
      if (this.loading) return
      this.loading = true
      try {
        await this.getCurExternalId()
        if (userWeChat) {
          // 已关联
          await this.$dialog.confirm({
            title: '提示',
            message: `该线索已关联微信客户“${userWeChatName}”,确定重新关联？`,
          })
        }
        loading.showLoading()
        const customer = await baseDataServices.getExternalDetail({
          externalUserId: this.userId,
        })
        if (!customer.externalUserid) throw new Error('未查询到外部联系人信息')
        await baseDataServices.setWechat({
          id,
          userAvatar: customer.avatar,
          userWeChat: customer.externalUserid,
          userWeChatName: customer.name,
        })
        this.loading = false
        this.onRefresh()
        this.goDetail({ id })
      } catch (error) {
        this.loading = false
        if (error !== 'cancel' && error.message) {
          this.$toast(error.message)
        }
        console.error(error)
      }
    },
    onSelect(item) {
      if (this.currentObj.id === item.id) {
        this.currentObj = {}
      } else {
        this.currentObj = item
      }
    },
    onRefresh() {
      this.currentObj = {}
      this.$refs.list.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr: this.searchStr,
        // hatchUserIds: [this.$store.state.user.userInfo.id]
      }
      loading.showLoading()
      return clueListPost(params).then((res) => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    goDetail({ id }, replace = false) {
      this.$router.push({
        path: '/clue-incubation/details',
        query: {
          id,
          contextEntry: this.contextEntry,
          replace
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.no-data-overlay {
  background: white;
}
.single-chat-index-page {
	padding-bottom: 10px;
	margin-bottom: 42px;
	/deep/ .list {
		padding-bottom: 0;
	}
	.total {
		height: 22px;
		font-size: 14px;
		font-family: HYQiHei, HYQiHei;
		font-weight: normal;
		color: #262626;
		line-height: 22px;
		margin: 9px 16px 0;
	}
	.item-wrap {
		position: relative;
		.select-icon {
			font-size: 0;
			padding: 4px;
			position: absolute;
			top: 50%;
			right: 24px;
			transform: translateY(-50%);
			cursor: pointer;
		}
	}
	.actions {
		display: flex;
		height: 44px;
		align-items: center;
		cursor: pointer;
	}
}
.footer {
	padding: 10px 16px 10px 0;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 500px;
	background: white;
	text-align: right;
}
</style>
