<template>
	<div v-if="contextEntry" class="clue-incubation-page">
		<SingleChatIndex
			v-if="['single_chat_tools', 'group_chat_tools'].includes(contextEntry)"
			:contextEntry="contextEntry"
		/>
		<WorkbenchIndex v-else />
	</div>
</template>
<script>
import SingleChatIndex from './SingleChatIndex.vue'
import WorkbenchIndex from './WorkbenchIndex.vue'
import { getWxEntry } from '@/utils'
import vendorServices from '@/services/vendorServices'
import loading from '@/utils/loading'
export default {
  name: 'ClueIncubationPage',
  components: {
    WorkbenchIndex,
    SingleChatIndex,
  },
  data() {
    return {
      contextEntry: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.contextEntry) {
        const ua = window.navigator.userAgent
        if (ua.includes('wxwork')) {
          loading.showLoading()
          vendorServices.wxAgentConfig(
            async () => {
              try {
                vm.contextEntry = await getWxEntry()
              } catch (error) {
                vm.$toast(error.message || error)
              }
              loading.hideLoading()
            },
            (error) => {
              vm.$toast(error.message || error)
            }
          )
        } else {
          vm.contextEntry = 'normal'
        }
        vm.$store.commit('app/addKeepAlive', 'ClueIncubationPage')
      }
    })
  },
}
</script>
<style lang="less" scoped></style>
