<template>
<div class="label-component">
  <template v-if="special">
    <div v-for="(item, index) in specialData" :key="index" :class="['item', `item_${item.code}`]">{{ item.name }}</div>
  </template>
  <template v-else>
    <div v-for="(item, index) in data" :key="index" class="item">{{ item }}</div>
  </template>
</div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default:() => ([])
    },
    special: { // 特殊处理 风险类型的标签 数据类型为对象
      type: Boolean,
      default: false
    },
    specialData: {
      type: Array,
      default:() => ([])
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.label-component{
  margin-top: 8px;
  display: flex;
  .item{
    padding: 0 8px;
    height: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #0040C5;
    color: #0040C5;
    margin-right: 4px;
    &.item_4009001{
      border: none;
      background: #E4002C;
      color: #fff;
      border-radius: 0;
    }
  }
}
</style>
