<!-- 倒计时 -->
<template>
  <div class="countDown">
    <!-- <img :src="countDownIcon"/> -->
    <van-icon name="underway-o" size="14" />
    <span>{{ countDownTxt }}</span>
  </div>
</template>
<script>
import countDownIcon from '@/images/countDown.svg'
import moment from 'moment'
import { i18n } from '@/main'
export default {
  props: {
    data:{
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      countDownIcon,
      interval: null,
      countDownTxt: '',
    }
  },
  inject: ['sendGrandSon'],
  watch: {
    'data.planRecycleTime': {
      handler(val) {
        if (this.interval) clearInterval(this.interval)
        this.interval = setInterval(() => {
          let s = -this.$moment().diff(val, 'seconds')
          if (s > 0) {
            this.getShowOvertime(s)
          } else if (s == 0) {
            this.sendGrandSon(this.data.id)
          }
        }, 1000)
      },
      immediate: true
    },
  },
  mounted() {
    
  },
  methods: {
    getShowOvertime(s) {
      const hours = Math.floor(moment.duration(s, 'seconds').asHours())
      const minutes = moment.utc(s * 1000).format('mm')
      const seconds = moment.utc(s * 1000).format('ss')
      const lang = i18n.locale
      if (lang.includes('zh')) {
        this.countDownTxt = `${hours}h${minutes}min${seconds}s后回收`
      } else {
        this.countDownTxt = `Recovered after ${hours}h${minutes}min${seconds}s`
      }
    }
  }
}
</script>
<style lang="less" scoped>
.countDown{
  // color: #E4002C;
  color: rgba(13, 23, 26, .75);
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img{
    width: 16px;
  }
}
</style>
