<script>
import wxImg from '@/images/wx.svg'
import { copyText } from '@/utils/index'
export default {
  props:{
    name:{
      type: String,
      default:''
    },
    phone: { // 客户手机号
      type: String,
      default:''
    },
    isShowImg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      wxImg
    }
  },
  methods:{
    // 添加好友页面
    openAddCustomer() {
      // const _this = this
      // if (wx?.setClipboardData){
      //   wx.setClipboardData({
      //     data: _this.phone, // 设置的
      //     success: function(res) {
      //       _this.$toast(_this.$t('号码复制成功'))
      //     }
      //   })
      // } else {
      //   navigator.clipboard.writeText(_this.phone).then(() => {
      //     _this.$toast(_this.$t('号码复制成功'))
      //   })
      // }
      
      copyText(this.phone, this.$t('号码复制成功'))
      setTimeout(() => {
        window.wx.invoke('navigateToAddCustomer',{},
          function(res) {
            console.log('navigateToAddCustomer res:',res)
          }
        )
      }, 2000)
    }
  }
}
</script>
<template>
<div class="img" @click.stop="openAddCustomer">
  <img v-if="isShowImg" :src="wxImg" />
  <span v-if="name" class="name" style="margin-left:8px;">{{ name }}</span>
</div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
</style>
