<script>
import qwImg from '@/images/qw.png'
import { copyText } from '@/utils/index'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'

export default {
  props:{
    chatId:{
      type: String,
      default:''
    },
    externalUserIds: {
      type: String,
      default:''
    },
    name:{
      type: String,
      default:''
    },
    phone: {
      type: String,
      default:''
    },
    showCopyText: {
      type: Boolean,
      default: true
    },
    leadId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      qwImg
    }
  },
  methods:{
    async openChat() {
      if (this.showCopyText) copyText(this.phone, this.$t('号码复制成功'))
      let obj = { chatId: this.chatId }
      if (!this.chatId && this.leadId) {
        loading.showLoading()
        try {
          const { chatId } = await clueServices.getChatByLeadId({ leadId: this.leadId })
          if (chatId) { obj.chatId = chatId }
        } catch (error) {}
        loading.hideLoading()
      } 
      if (!obj.chatId) obj.externalUserIds = this.externalUserIds
      wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        // userIds: '', // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        // externalUserIds: this.externalUserIds, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        // groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        // chatId: this.chatId, // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        ...obj,
        success: function(res) {
          // var chatId = res.chatId // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: (res)=> {
          if (res.errMsg.indexOf('function not exist') > -1){
            alert(this.$t('版本过低请升级'))
          } else if (res.errMsg.indexOf('openEnterpriseChat:invalid chatid') >-1){
            this.$toast('你不是群成员，无法参与会话')
          } else {
            this.$toast(res.errMsg)
          }
        }
      })
      // const _this = this
      // if (wx?.setClipboardData){
      //   wx.setClipboardData({
      //     data: _this.phone, // 设置的
      //     success: function(res) {
      //       _this.$toast(_this.$t('号码复制成功'))
      //       setTimeout(() => {
      //         // 当以上两类对话框都无法打开； 则判断若用户不在客户群，提示“你不是群成员，无法打开会话” ； 若用户与线索关联的微信客户不是好友，则提示“你与微信客户不是好友，无法打开会话”
      //         wx.openEnterpriseChat({
      //           // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
      //           userIds: '', // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
      //           externalUserIds: _this.externalUserIds, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
      //           groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
      //           chatId: _this.chatId, // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
      //           success: function(res) {
      //             // var chatId = res.chatId // 返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
      //           // 回调
      //           },
      //           fail: function(res) {
      //             if (res.errMsg.indexOf('function not exist') > -1){
      //               alert(_this.$t('版本过低请升级'))
      //             }
      //           }
      //         })
      //       }, 2000)
      //     }
      //   })
      // } else {
      //   navigator.clipboard.writeText(_this.phone).then(() => {
      //     _this.$toast(_this.$t('号码复制成功'))
      //   })
      // }
    }
  }
}
</script>
<template>
<div class="img" @click.stop="openChat">    
  <img  :src="qwImg"  />
  <span v-if="name" style="margin-left:8px;">{{ name }}</span>
</div>
</template>
<style lang="less" scoped>
.img{
  cursor: pointer;
  display: flex;
  align-items: center;
  img{
    width: 32px;
    height: 32px;
    &:hover,&:active{
      box-shadow: 0px 2px 5px #21212138;
      border-radius: 50%;
    }
  }
}
</style>
